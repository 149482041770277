.workSection {
    /* background: #e8e8e8;
    padding-bottom: 140px;
    padding-top: 180px;
    -webkit-clip-path: polygon(63% 6%, 100% 0, 100% 100%, 32% 100%, 0 100%, 0 0);
    clip-path: polygon(0% 10%, 100% 0%, 100% 100%, 100% 100%, 0% 90%, 0% 0%); */
}
.headerWrapper {
    display: inline-block;
}

.cardContent {
    position: relative;
    text-align: left;
    padding: 10px 10px 0px 10px;
}

.category {
    font-weight: 600;
    font-size: 10px;
}

.categoryLink {
    font-weight: 600;
    color: #16c2b9;
    font-size: 10px;
}

.caption {
    width: 100%;
    color: black;
    text-transform: uppercase;
    font-size: 15px;
    text-align: left;
    float: left;
    border-bottom: 1px solid #eaeaea;
    display: block;
    font-weight: 600;
    padding: 4px 0px 10px 0px;
    margin: 0px 0px 5px 0px;
}

.card-content::after {
    content: "";
    display: inline-block;
    width: 50px;
    position: absolute;
    top: 30px;
    right: 0px;
    z-index: 7;
    height: 6px;
    border-top: 1px solid #ffffff;
}

.previewText {
    padding-top: 5px;
}

.workCard {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    min-width: 0;
    word-wrap: break-word;
    margin: 15px 0px;
    border: 3px solid white;
}

@media only screen and (max-width: 767px) {
    .workSection {
        padding-top: 30px;
        padding-bottom: 15px;
    }
}