.sidebar {
    height: 100vh;
    width: 300px;
    position: fixed;
    left: 0px;
    z-index: 9;
    background-color: #16c2b9;
    left: -300px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.main.toggled .sidebar {
    left: 0px;
    box-shadow: 4px 1px 8px 2px rgba(101, 101, 101, 0.45);
}

.sidebar a {
    color: #fff;
    font-size: 15px;
}

.menuItem {
    padding: 5px 0px;
    text-align: left;
}

.menu-items {
    position: relative;
    top: 50%;
    list-style: none;
    padding: 20px;
    transform: translateY(-50%);
}