body {
    margin: 0;
    font-family: 'Open Sans', monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main.toggled .sidebarBtn {
    left: -40px;
  }