.icons {
    position: absolute;
    bottom: 10px;
    left: auto;
    right: auto;
    width: 100%;
    text-align: center;
}

.icons a {
    color: #434343;
    padding: 0px 10px;
    display: inline-block;
    text-align: center;
    font-size: 18px;
}