.sidebarBtn {
    position: fixed;
    left: 0;
    top: 10px;
    border-radius: 0 4px 4px 0px;
    width: 35px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    transition-delay: 0.3s;
    cursor: pointer;
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    z-index: 1;
}

.hamburger {
    position: fixed;
    top: 10px;
    left: 15px;
    z-index: 79;
}

.hamburger .line{
    width: 30px;
    height: 3px;
    background-color: black;
    display: block;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .hamburger:hover{
    cursor: pointer;
  }
  
  /* ONE */
  
  .hamburger.is-active .line:nth-child(2){
    opacity: 0;
  }
  
  .hamburger.is-active .line:nth-child(1){
    -webkit-transform: translateY(9px) rotate(45deg);
    -ms-transform: translateY(9px) rotate(45deg);
    -o-transform: translateY(9px) rotate(45deg);
    transform: translateY(9px) rotate(45deg);
  }
  
  .hamburger.is-active .line:nth-child(3){
    -webkit-transform: translateY(-7px) rotate(-45deg);
    -ms-transform: translateY(-7px) rotate(-45deg);
    -o-transform: translateY(-7px) rotate(-45deg);
    transform: translateY(-7px) rotate(-45deg);
  }