.footer {
    background-color: #1b1e29;
    color: #fff;
    padding: 30px 0px 30px 0px;
    bottom: 0px;
    width: 100%;
}

.footerName {
    color: #ffffff;
    font-weight: 300;
    letter-spacing: 0px;
    font-size: 17px;
}

.footerName span {
    font-weight: 600;
}

.footer .container .row .col-3 small {
    color: #ffffff;
}

.footerMenu {
    list-style: none;
    padding-left: 0px;
}

.footerMenu li {
    font-size: 13px;
}

.footerMenu div li a:hover {
        color: #16c2b9;
}

.contact p, .contact p a {
    color: #ffffff;
    padding: 0px 15px 0px 0px;
    display: block;
    text-align: left;
    font-size: 13px;
    transition: color 0.5s;
}

.contact p {
    margin-bottom: 4px;
}

.contact p a  {
    display: inline-block;
}

.contact a:hover {
    color: #16c2b9;
}

.icons {
    float: left;
    margin-top: 15px;
}

.icons a {
    color: #ffffff;
    padding: 0px 15px 0px 0px;
    display: inline-block;
    text-align: center;
    font-size: 18px;
    transition: color 0.5s;
}

.icons a:hover {
    color: #16c2b9;
}

.copyright {
    color: #ffffff;
    padding: 0px 0px 20px 0px;
    display: block;
    font-size: 10px;
}

.footerMenu div a {
    color: #ffffff;
}

@media only screen and (max-width: 767px) {
    .menuItem, .contact p, .contact p a {
        text-align: center;
        padding: 5px 0px;
    }
    .contact p a {
        display: block;
    }
    .icons {
        float: left;
        margin: 15px auto;
        width: 100%;
    }

.icons a {
    padding: 0px 8px;
}

    .copyright {
        text-align: center;
    }
}