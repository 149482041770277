form.contact-form {
    margin-top: 3rem;
    max-width: 700px;
    margin: 0px auto;
}

.form-group {
    margin-top: 1rem;
    margin-bottom: 3rem;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: transparent;
    background-clip: padding-box;
    border: none;
    border-bottom: 1px solid #1b1e29;
    border-radius: 0px;
    transition: all .45s ease-in-out;
}

.contactBlock {
    text-align: left;
    margin-top: 4rem;
}

.contactBlock h4 {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 17px;
    margin-bottom: 25px;
}

.contactBlock p {
    font-size: 13px;
}

button.button.button-primary:disabled {
    background-color: #a4a4a4;
    border-color: #5b5b5b;
}

input.form-control.has-error {
    border-bottom: 1px solid #e00000;
}

p.warningMessage {
        background-color: #e35a5f;
        margin-top: 10px;
        border-radius: 5px;
        color: white;
        padding: 4px;
}

.contactBlock p span {
    font-size: 15px;
    font-weight: 600;
    padding-right: 3px;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    outline: 0;
    box-shadow: 0px 0px 1px #c7c7c7;
    border-bottom: 1px solid #16c2b9;
}

textarea.form-control {
    outline: none;
}

section.form {
    clip-path: polygon(0 7%, 100% 0, 100% 100%, 0% 100%);
    background: #f9f8fd;
    padding-bottom: 0px;
}

@media only screen and (max-width: 767px) {
    .row.portfolioRow .col-sm-12:nth-child(even) {
        margin-top: 0px;
    }
    .copyright {
        padding: 20px 0px 20px 0px;
        text-align: left;
    }
}