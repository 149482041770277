@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

h1.blogHeading:before {
    content: "";
    display: inline-block;
    width: 50px;
    position: absolute;
    top: 60px;
    right: 0px;
    z-index: 7;
    height: 6px;
    border-top: 1px solid #16c2b9;
}

.blogContent {
    margin-top: 70px;
}

.appHeader {
    background-position: center center;
    min-height: 85vh;
    display: flex;
    background-size: cover;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.blogHeaderWrapper {
    position: absolute;
    right: 50px;
    h1{
        font-size: 32px;
        font-weight: 300;
        letter-spacing: 0px;
        color: #051441;
        text-transform: uppercase;
        margin-bottom: 0px;
    }
}


.blogHeaderWrapper h1:after {
    display: none;
}

.blogHeaderWrapper h1 span {
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #051441;
    text-transform: uppercase;
    margin-bottom: 0px;
}

.blogHeaderWrapper h2:after {
    content: "";
    display: inline-block;
    width: 50px;
    position: absolute;
    top: 35px;
    right: 0px;
    z-index: 7;
    height: 6px;
    border-top: 1px solid #16c2b9;
}

.titleContainer {
    top: 50%;
    position: relative;
    transform: translateY(-50%);
    padding: 0px 20px;
}

.projectInfo {
    margin: 0px 0px 0px 0px;
    position: relative;
    width: 100%;
    max-width: 550px;
}

.projectDescription, .projectDescription h3, .projectDescription p {
    text-align: left;
}

.columnsLeft {
    -webkit-columns: 2 200px;
    -moz-columns: 2 200px;
    columns: 2 200px;
    -webkit-column-gap: 40px;
    -moz-column-gap: 40px;
    column-gap: 40px;
    text-align: left;
}

.info {
    width: 100%;
}

.info button {
    display: block;
    float: none;
    margin: 10px 0px;
}

.info button a, .info button svg {
    color: #ffffff;
}

.info svg {
    color: #16c2b9;
}

.iconRow, .aboutMeCols {
    width: 100%;
    display: flex;
    margin: 50px auto 0px auto;
}

.projectHeading {
    margin-top: 20px;
    display: inline-block;
    margin-left: 15px;
}

@media only screen and (max-width: 767px) {
    .projectInfo {
        margin-top: 20px;
    }
    .info {
        text-align: center;
    }
    .info svg {
        display: block;
        text-align: center;
        float: none;
        margin: 10px auto 0px auto;
    }
    .info button {
        margin: 10px auto;
    }
    .info button svg {
        margin: 0px 3px;
    }
    .info .projectHeading {
        margin-top: 20px;
        display: inline-block;
        margin-left: 0px;
    }
    .projectDescription, .projectDescription h3, .projectDescription p {
        text-align: center;
    }
    .blogHeaderWrapper {
        position: absolute;
        left: 20px;
        bottom: 25px;
        right: auto;
    }
    .blogHeaderWrapper h1, .blogHeaderWrapper h1 span {
        font-size: 25px;
    }

    h1.blogHeading:before {
        content: "";
        display: inline-block;
        width: 50px;
        position: absolute;
        top: 45px;
        right: 0px;
        z-index: 7;
        height: 6px;
        border-top: 1px solid #16c2b9;
    }
}