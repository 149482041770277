@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.aboutPage {
    background: #f9f8fd;
    -webkit-clip-path: polygon(0 7%, 100% 0, 100% 93%, 0% 100%);
    clip-path: polygon(0 0%, 100% 0, 100% 93%, 0% 100%);
    padding-bottom: 75px;
    padding-top: 75px;
}

.about.appHeader {
    height: 50vh;
    display: flex;
    flex-direction: column;
    min-height: auto;
    background-image: url('https://s3-eu-west-1.amazonaws.com/davidgkennedy.com/Portfolio+Images/about-bg.jpg');
    background-size: cover;
    align-items: center;
    justify-content: center;
    text-align: center;
}

header.about .container .headerWrapper h1:after {
    border-top: 1px solid #16c2b9;
    width: 55px;
}

.titleContainer {
    top: 50%;
    position: relative;
    transform: translateY(-50%);
    padding: 0px 20px;
}

.headshot {
    margin-top: 0px;
    max-width: 100%;
    height: auto;
}

.projectInfo {
    margin: 40px 0px 0px 0px;
    position: relative;
    width: 100%;
    max-width: 550px;
}

.columnsLeft {
    -webkit-columns: 2 200px;
    -moz-columns: 2 200px;
    columns: 2 200px;
    -webkit-column-gap: 40px;
    -moz-column-gap: 40px;
    column-gap: 40px;
    text-align: left;
}

.iconRow, .aboutMeCols {
    width: 100%;
    display: flex;
    margin: 50px auto 0px auto;
}

.projectHeading {
    margin-top: 20px;
}

.aboutMeRow {
    width: 100%;
    max-width: 700px;
    margin: 0px auto 35px auto;
    text-align: left;
}

@media only screen and (max-width: 992px) {
.about.appHeader {
    background-image: none;
    background: url('https://s3-eu-west-1.amazonaws.com/davidgkennedy.com/Portfolio+Images/home-bg.jpg');
    background-position: 95% -140px;
    background-repeat: no-repeat;
}
header.about .container .headerWrapper h1:after {
    border-top: 1px solid white;
}
    }