@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.appHeader {
    height: 100vh;
    background-image: url('https://s3-eu-west-1.amazonaws.com/davidgkennedy.com/Portfolio+Images/home-bg.jpg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    /* background-attachment: fixed; */
    align-items: center;
    justify-content: center;
    text-align: center;
}
  
.appLink {
    color: #61dafb;
}

.headerWrapper {
    display: inline-block;
    float: left;
}

.headerWrapper h1 {
    font-size: 45px;
    font-weight: 300;
    letter-spacing: 0px;
    display: block;
    color: #051441;
    text-transform: uppercase;
    margin-bottom: 0px;
    /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 10s ease infinite; */
    /* background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}

.headerWrapper h1::after {
    content: "";
    display: none;
}

.headerWrapper h1 span {
        font-size: 45px;
        font-weight: 700;
        letter-spacing: 0px;
        /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
        background-size: 400% 400%;
        animation: gradient 10s ease infinite;
        color: #000;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; */
}

.headerWrapper h2 {
    color: #051441;
    font-weight: 300;
}

.intro-btn {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;
    color: #000;
    border-radius: 50%;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.headerWrapper h2:after {
    content: "";
    display: inline-block;
    width: 50px;
    position: absolute;
    top: 35px;
    right: 0px;
    z-index: 7;
    height: 6px;
    border-top: 1px solid #16c2b9;
}

@media only screen and (max-width: 992px) {
    .appHeader {
        background-image: none;
        background: url(https://s3-eu-west-1.amazonaws.com/davidgkennedy.com/Portfolio+Images/home-bg.jpg);
        background-position: 95% -140px;
        background-repeat: no-repeat;
    }
    .headerWrapper {
        display: inline-block;
        float: none;
        position: relative;
        margin: 0px auto;
        width: 300px;
    }

    .headerWrapper h1 {
        font-size: 38px;
        color: white;
    }

    .headerWrapper h1 span {
        font-size: 38px;
        color: white;
    }

    .headerWrapper h2 {
        font-size: 18px;
        font-weight: 300;
        color: white;
        position: absolute;
        right: 2px;
        display: inline-block;
        letter-spacing: 0px;
    }

    .headerWrapper h2:after {
        border-top: 1px solid white;
    }
}