.headerWrapper {
    display: inline-block;
}

.columnsJustify {
    -webkit-columns: 3 200px;
    -moz-columns: 3 200px;
    columns: 3 200px;
    -webkit-column-gap: 40px;
    -moz-column-gap: 40px;
    column-gap: 40px;
    text-align: justify;
}

.iconRow, .aboutMeCols {
    width: 100%;
    max-width: 700px;
    margin: 35px auto 0px auto;
}

.aboutMeCols p {
    text-align: center;
}

.AboutMeCopy {
    text-align: left;
}

.categories {
    text-align: center;
    padding: 0px 5px;
}

.aboutMeCols .row .col-4 {
    border-right: 1px solid #eaeaea;
}

.aboutMeCols .row .col-4:nth-child(3) {
    border-right: none;
}

.categories svg {
    color: #16c2b9;
    text-align: left;
}

.categories h4 {
    color: #051441;
    margin-top: 15px;
    text-align: center;
}

.categories p {
    font-size: 13px;;
}

.aboutBtn {
    margin-bottom: 0px;
}


@media only screen and (max-width: 767px) {
.headerWrapper {
    display: inline-block;
    float: none;
    position: relative;
    margin: 0px auto;
    width: 300px;
}
}